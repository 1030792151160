import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import ReactTyped from "react-typed";

import PostsSection from "./homeComponents/PostsSection";
import { Fade } from "react-reveal";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

const Homepage = ({
  textEnter,
  textLeave,
  navbar,
  variants,
  mainControls,
  refInimate,
  txtEnter,
  cursorVariant,
  setAllPosts,
}) => {
  const homepage = useSelector((state) => state.news.allHomePageTexts);

  let str = homepage?.acf?.about_text_marquee;
  let arr = str?.split(",");

  const navigate = useNavigate();
  const location = useLocation();

  const [hom, setHom] = useState(null);
  // axios
  // .get("/wp-json/wp/v2/pages/3246")
  // .then((res) => {
  //   setHom(res.data);
  // })
  // .catch((err) => {
  //   console.log(err?.response);
  // });
  // console.log("dddd", hom?.yoast_head_json?.title)

  const loadTwitterJs = () => {
    let script = document.createElement("script");
    script.async = true;
    script.type = "application/ld+json";
    script.className = "yoast_head_json";
    script.innerHTML = JSON.stringify(homepage?.yoast_head_json?.schema);
    var scriptElement = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (!scriptElement) {
      document.head.appendChild(script);
    }
  };
  const metaInjector = (name, content) => {
    let metaName = document.createElement("meta");
    metaName.async = true;
    metaName.name = name;
    metaName.content = content;
    var exists = document.querySelector(`meta[name=${name}]`);
    if (!exists) {
      document.head.appendChild(metaName);
    }
  };
  const metaPropInjector = (property, content) => {
    let metaProperty = document.createElement("meta");
    metaProperty.async = true;
    metaProperty.setAttribute("property", property);
    metaProperty.setAttribute("content", content);
    const meta = document.querySelector(`meta[property="${property}"]`);
    if (!meta) {
      document.head.appendChild(metaProperty);
    }
  };

  // const metaPropInjea
  const titleInjector = () => {
    let title = document.createElement("title");
    title.async = true;
    title.innerHTML = homepage?.yoast_head_json?.title;
    let pageTitle = document.querySelector("title");
    if (!pageTitle?.tagName) {
      document.head.appendChild(title);
    }
  };

  // function MyComponent() {
  // useEffect(() => {
  //   // Create a new meta element
  //   const metaTag = document.createElement('meta');

  //   // Set the meta properties
  //   metaTag.setAttribute('property', 'og:title');
  //   metaTag.setAttribute('content', 'Your Meta Title');

  //   // Append the meta element to the document head
  //   document.head.appendChild(metaTag);

  //   // Clean up when the component unmounts (optional)
  //   return () => {
  //     // Remove the added meta element when the component unmounts
  //     document.head.removeChild(metaTag);
  //   };
  // }, []);

  useEffect(() => {
    if (homepage) {
      metaInjector(
        "description",
        "Media General Ghana is a leading media company in Ghana, providing news, information, and entertainment to Ghanaians."
      );
      metaInjector("title", "Homepage - Media General Ghana");
      metaInjector("keywords");
      metaInjector(
        "robots",
        "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      );
      metaPropInjector("og:url", "https://mg.com.gh/");
      metaPropInjector("og.title", "Homepage - Media General Ghana");
      metaPropInjector(
        "og:description",
        "Media General Ghana is a leading media company in Ghana, providing news, information, and entertainment to Ghanaians."
      );
      metaPropInjector("og:type", "article");
      metaPropInjector(
        "og:image",
        "https://cms.mg.com.gh/wp-content/uploads/2023/08/Paa-Kwesi-Asare-BBC-Komla-Dumor2.jpg"
      );
      metaPropInjector("og:image:width", "962");
      metaPropInjector("og:image:height", "2560");
      metaPropInjector("twitter:title", "Homepage - Media General Ghana");
      metaPropInjector(
        "twitter:description",
        "Media General Ghana is a leading media company in Ghana, providing news, information, and entertainment to Ghanaians."
      );
      metaPropInjector("twitter:card", "summary_large_image");
      metaPropInjector(
        "twitter:image",
        "https://cms.mg.com.gh/wp-content/uploads/2023/08/Paa-Kwesi-Asare-BBC-Komla-Dumor2.jpg"
      );
      metaPropInjector("twitter:image:alt", "Homepage Hero Image");
      metaPropInjector("twitter:image:width", "962");
      metaPropInjector("twitter:image:height", "2560");
      metaPropInjector("twitter:url", "https://mg.com.gh/");
      metaPropInjector("twitter:site", homepage?.yoast_head_json?.twitter_site);
      metaPropInjector("og:site_name", homepage?.yoast_head_json?.og_site_name);
      metaPropInjector("og:locale", homepage?.yoast_head_json?.og_locale);
      metaPropInjector(
        "article:publisher",
        homepage?.yoast_head_json?.article_publisher
      );
      metaPropInjector(
        "article:modified_time",
        homepage?.yoast_head_json?.article_modified_time
      );

      titleInjector();
      loadTwitterJs();
    }

  }, [homepage]);
  return (
    <div className="hom">
      <motion.div
        className="cursor"
        variants={variants}
        animate={cursorVariant}
        onMouseEnter={textLeave}
      />
      <Navbar
        textEnter={txtEnter}
        textLeave={textLeave}
        navbar={navbar}
        location={location}
      />
      <div className="video-cover">
        <video
          src={homepage?.acf?.hero_background_media}
          muted
          autoPlay
          loop
          className="video-background"
        />
      </div>
      <div className="content-width">
        <div className="container">
          <div className="col-12">
            <div
              className="d-flex mb-4"
              onMouseEnter={textEnter}
              onMouseLeave={textLeave}
            >
              <div className="line mt-1 mt-lg-2 me-2"></div>
              <div className="content-font">
                <div>
                  <Fade bottom cascade>
                    <h1 className="my-auto">WE ARE</h1>
                  </Fade>
                </div>
              </div>
            </div>
            <div
              className="content-font-1"
              onMouseEnter={textEnter}
              onMouseLeave={textLeave}
            >
              <Fade bottom cascade>
                <div className="content-font-1-h2">
                  <h2
                    className="cont-h2"
                    dangerouslySetInnerHTML={{
                      __html: homepage?.acf?.hero_text,
                    }}
                  ></h2>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="content-margin">
        <div className="container">
          <div className="col-12">
            <div
              className="d-flex mb-4"
              onMouseEnter={textEnter}
              onMouseLeave={textLeave}
            >
              <div className="line mt-1 mt-lg-2 me-2"></div>
              <div className="content-font">
                <Fade bottom cascade>
                  <h1 className="my-auto">WE ARE</h1>
                </Fade>
              </div>
            </div>
            <div className="content-font-2 cont-2-font">
              <Fade bottom cascade>
                <h2 onMouseEnter={textEnter} onMouseLeave={textLeave}>
                  {homepage?.acf?.sub_hero_text}
                </h2>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          navbar ? "body-width body-height-active" : "body-width body-height"
        }
      >
        <div className="container">
          <div className="col-12">
            <div
              className="d-flex mb-5"
              onMouseEnter={textEnter}
              onMouseLeave={textLeave}
            >
              <motion.div
                className="line mt-1 mt-lg-2 me-2"
                animate={{ mixBlendMode: "difference" }}
              ></motion.div>
              <motion.div
                className="content-font"
                animate={{ mixBlendMode: "difference" }}
              >
                <Fade bottom cascade>
                  <h1 className="my-auto">ABOUT US</h1>
                </Fade>
              </motion.div>
            </div>
            <div className="content-font-3">
              <Fade bottom cascade>
                <motion.h2
                  onMouseEnter={textEnter}
                  onMouseLeave={textLeave}
                  animate={{ mixBlendMode: "difference" }}
                >
                  {homepage?.acf?.about_text}
                </motion.h2>
                <motion.h2 className="text-underline">
                  <ReactTyped
                    strings={["content.", "information.", "knowledge"]}
                    loop={Infinity}
                    wrapper="span"
                    typeSpeed={150}
                    backSpeed={100}
                  />
                </motion.h2>
              </Fade>
            </div>
          </div>
          <motion.div className="mt-5" animate={{ mixBlendMode: "difference" }}>
            <Fade bottom cascade>
              <input
                type="button"
                value="learn more"
                className="btn btn-outline-white rounded-pill px-lg-5"
                onMouseEnter={textEnter}
                onMouseLeave={textLeave}
              />
            </Fade>
          </motion.div>
        </div>
      </div>
      <div
        className={
          navbar ? "body-width body-height1-active" : "body-width body-height1"
        }
      >
        <div className="container">
          <div className="col-12">
            <div className="d-flex mb-4">
              <motion.div
                animate={{ mixBlendMode: "difference" }}
                className="line mt-1 mt-lg-2 me-2"
              ></motion.div>
              <div className="content-font">
                <Fade bottom cascade>
                  <motion.h1
                    className="my-auto"
                    animate={{ mixBlendMode: "difference" }}
                  >
                    OUR BRANDS
                  </motion.h1>
                </Fade>
              </div>
            </div>
            <div className="content-font-4">
              <Fade bottom cascade>
                <motion.h2
                  onMouseEnter={textEnter}
                  onMouseLeave={textLeave}
                  animate={{ mixBlendMode: "difference" }}
                >
                  {homepage?.acf?.brands_intro_text}
                </motion.h2>
              </Fade>
            </div>
          </div>
          <motion.div className="mt-5" animate={{ mixBlendMode: "difference" }}>
            <Fade bottom cascade>
              <input
                type="button"
                value="see all brands"
                className="btn btn-outline-white rounded-pill px-lg-5 mt-2"
                onMouseEnter={txtEnter}
                onMouseLeave={textLeave}
                onClick={() => navigate("/our-brands")}
              />
            </Fade>
          </motion.div>
        </div>
      </div>
      <div className="logo-section bg-white">
        <div className="container">
          <div className="col-12">
            <div className="d-flex justify-content-between mb-5">
              <div className="logo-cover  d-flex align-items-center">
                <img
                  src="/assets/img/tv3.png"
                  alt=""
                  className="img-fluid"
                  width="60%"
                />
              </div>
              <div className="logo-cover d-flex align-items-center">
                <img
                  src="/assets/img/adesa.png"
                  alt=""
                  className="img-fluid"
                  width="60%"
                />
              </div>
              <div className="logo-cover d-flex align-items-center">
                <img src="/assets/img/akoma.png" alt="" width="60%" />
              </div>
              <div className="logo-cover d-flex align-items-center">
                <img src="/assets/img/newson3.png" alt="" width="60%" />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="logo-cover  d-flex align-items-center">
                <img
                  src="/assets/img/3fm.png"
                  alt=""
                  className="img-fluid"
                  width="60%"
                />
              </div>
              <div className="logo-cover d-flex align-items-center">
                <img
                  src="/assets/img/3xtra.png"
                  alt=""
                  className="img-fluid"
                  width="60%"
                />
              </div>
              <div className="logo-cover d-flex align-items-center">
                <img src="/assets/img/onua.png" alt="" width="60%" />
              </div>
              <div className="logo-cover d-flex align-items-center">
                <img src="/assets/img/mg-digital.png" alt="" width="60%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="body-width body-height1-active">
        <div className="container">
          <div className="col-12">
            <div className="d-flex mb-4">
              <motion.div
                animate={{ mixBlendMode: "difference" }}
                className="line mt-1 mt-lg-2 me-2"
              ></motion.div>
              <div className="content-font">
                <Fade bottom cascade>
                  <motion.h1
                    className="my-auto"
                    animate={{ mixBlendMode: "difference" }}
                  >
                    LATEST NEWS
                  </motion.h1>
                </Fade>
              </div>
            </div>
            <div className="content-font-4">
              <Fade bottom cascade>
                <motion.h2
                  onMouseEnter={textEnter}
                  onMouseLeave={textLeave}
                  animate={{ mixBlendMode: "difference" }}
                >
                  {homepage?.acf?.news_intro_text}
                </motion.h2>
              </Fade>
            </div>
          </div>
          <motion.div className="mt-5" animate={{ mixBlendMode: "difference" }}>
            <Fade bottom cascade>
              <input
                type="button"
                value="see all news"
                className="btn btn-outline-white rounded-pill px-lg-5 mt-2"
                onMouseEnter={txtEnter}
                onMouseLeave={textLeave}
                onClick={() => navigate("/news")}
              />
            </Fade>
          </motion.div>
        </div>
      </div>
      <PostsSection navbar={navbar} />

      <div className="body-width join-section">
        <div className="container">
          <div className="col-12">
            <div className="join-background">
              <img src="/assets/img/join.jpg" alt="" />
            </div>
            <div className="d-flex mb-4">
              <motion.div
                animate={{ mixBlendMode: "difference" }}
                className="line mt-md-2 mt-1 me-2"
              ></motion.div>
              <div className="content-font">
                <Fade bottom cascade>
                  <motion.h1
                    className="my-auto"
                    animate={{ mixBlendMode: "difference" }}
                  >
                    JOIN OUR TEAM
                  </motion.h1>
                </Fade>
              </div>
            </div>
            <div className="content-font-4">
              <Fade bottom cascade>
                <motion.h2
                  onMouseEnter={textEnter}
                  onMouseLeave={textLeave}
                  animate={{ mixBlendMode: "difference" }}
                >
                  {homepage?.acf?.join_us_text}
                </motion.h2>
              </Fade>
            </div>
          </div>
          <motion.div className="mt-5" animate={{ mixBlendMode: "difference" }}>
            <Fade bottom cascade>
              <a href="/contact-us">
                <input
                  type="button"
                  value="get in touch"
                  className="btn btn-outline-white rounded-pill px-lg-4 mt-1"
                  onMouseEnter={txtEnter}
                  onMouseLeave={textLeave}
                />
              </a>
            </Fade>
          </motion.div>
        </div>
      </div>
      <div className="scroll">
        <div className="RightToLeft">
          <p>{homepage?.acf?.reach_marquee_top} —</p>
          <p>{homepage?.acf?.reach_marquee_top} —</p>
          <p>{homepage?.acf?.reach_marquee_top} —</p>
        </div>
        <div className="LeftToRight">
          <p>{homepage?.acf?.reach_marquee_bottom} —</p>
          <p>{homepage?.acf?.reach_marquee_bottom} —</p>
          <p>{homepage?.acf?.reach_marquee_bottom} —</p>
        </div>
      </div>
      <Footer textEnter={textEnter} txtEnter={txtEnter} textLeave={textLeave} />
    </div>
  );
};
export default Homepage;
