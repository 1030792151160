import { Route, Routes, useLocation } from "react-router-dom";

import {
  AnimatePresence,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import HomePage from "../pages/Homepage";
import { useEffect, useRef, useState } from "react";
import ArticlePage from "../pages/ArticlePage.js";
import NewsPage from "../pages/NewsPage.js";
import CompanyPage from "../pages/CompanyPage.js";
import ContactUsPage from "../pages/ContactUsPage.js";
import ScrollToTop from "./ScrollToTop.js";
import { useDispatch } from "react-redux";
import useGetFetch from "./customHooks/useGetFetch.js";
import {
  setAllAboutPageTexts,
  setAllBrandPageTexts,
  setAllHomePageTexts,
  setAllBrands,
  setAllOffices,
  setAllCompanies,
} from "../app/newsSlice.js";
import BrandsPage from "../pages/BrandsPage.js";
import AboutPage from "../pages/AboutPage.js";
import XmlPage from "../XmlPage";

const AnimatedRoute = () => {
  const dispatch = useDispatch();
  const { data: homepage } = useGetFetch("/wp-json/wp/v2/pages/3246");
  const { data: aboutpage } = useGetFetch("/wp-json/wp/v2/pages/3286");
  const { data: brandpage } = useGetFetch("/wp-json/wp/v2/pages/3308");
  const { data: brands } = useGetFetch("/wp-json/wp/v2/brand");
  const { data: offices } = useGetFetch("/wp-json/wp/v2/office");
  const { data: companies } = useGetFetch("/wp-json/wp/v2/company");

  dispatch(setAllHomePageTexts(homepage));
  dispatch(setAllAboutPageTexts(aboutpage));
  dispatch(setAllBrandPageTexts(brandpage));
  dispatch(setAllBrands(brands));
  dispatch(setAllOffices(offices));
  dispatch(setAllCompanies(companies));

  const location = useLocation();
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [scrollPosition, setScrollPosition] = useState({ y: 0 });
  const [cursorVariant, setCursorVariant] = useState("default");
  const mouseMove = (e) => {
    setMousePosition({
      x: e.clientX,
      y: e.clientY,
    });
  };
  useEffect(() => {
    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      backgroundColor: "white",
      mixBlendMode: "difference",
    },
    text: {
      height: 32,
      width: 32,
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      backgroundColor: "white",
      mixBlendMode: "difference",
    },
    txt: {
      height: 70,
      width: 70,
      x: mousePosition.x - 35,
      y: mousePosition.y - 35,
      backgroundColor: "white",
      mixBlendMode: "difference",
    },
  };

  const textEnter = () => setCursorVariant("text");
  const txtEnter = () => setCursorVariant("txt");
  const textLeave = () => setCursorVariant("default");

  const refInimate = useRef(null);
  const isInView = useInView(refInimate, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    mainControls.start("visible");
  }, [isInView]);
  const [navbar, setNavbar] = useState(false);
  const [navbarAbout, setNavbarAbout] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 1800) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
    if (window.scrollY >= 5380) {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  // const { scrollYProgress } = useScroll();
  // const x = useTransform(scrollYProgress, [0, 1], [0, 200]);
  // const xVlaue = useTransform(scrollYProgress, [1, 0], [-200, 0]);
  return (
    <AnimatePresence location={location} key={location.pathname}>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              textEnter={textEnter}
              textLeave={textLeave}
              navbar={navbar}
              variants={variants}
              mainControls={mainControls}
              refInimate={refInimate}
              txtEnter={txtEnter}
              cursorVariant={cursorVariant}
            />
          }
        />
        <Route
          path="/about-us"
          element={
            <AboutPage
              textEnter={textEnter}
              textLeave={textLeave}
              navbarAbout={navbarAbout}
              variants={variants}
              mainControls={mainControls}
              refInimate={refInimate}
              txtEnter={txtEnter}
              cursorVariant={cursorVariant}
            />
          }
        ></Route>
        <Route
          path="/article/:slug"
          element={
            <ArticlePage
              textEnter={textEnter}
              textLeave={textLeave}
              navbarAbout={navbarAbout}
              variants={variants}
              mainControls={mainControls}
              refInimate={refInimate}
              txtEnter={txtEnter}
              cursorVariant={cursorVariant}
            />
          }
        ></Route>
        {["/news/:year/:slug", "/news"].map((path, index) => {
          return (
            <Route
              path={path}
              element={
                <NewsPage
                  textEnter={textEnter}
                  textLeave={textLeave}
                  navbarAbout={navbarAbout}
                  variants={variants}
                  mainControls={mainControls}
                  refInimate={refInimate}
                  txtEnter={txtEnter}
                  cursorVariant={cursorVariant}
                />
              }
              key={index}
            />
          );
        })}

        <Route
          path="/our-brands"
          element={
            <BrandsPage
              textEnter={textEnter}
              textLeave={textLeave}
              navbarAbout={navbarAbout}
              variants={variants}
              mainControls={mainControls}
              refInimate={refInimate}
              txtEnter={txtEnter}
              cursorVariant={cursorVariant}
            />
          }
        />
        <Route
          path="/companies"
          element={
            <CompanyPage
              textEnter={textEnter}
              textLeave={textLeave}
              navbarAbout={navbarAbout}
              variants={variants}
              mainControls={mainControls}
              refInimate={refInimate}
              txtEnter={txtEnter}
              cursorVariant={cursorVariant}
            />
          }
        />
        <Route
          path="/contact-us"
          element={
            <ContactUsPage
              textEnter={textEnter}
              textLeave={textLeave}
              navbarAbout={navbarAbout}
              variants={variants}
              mainControls={mainControls}
              refInimate={refInimate}
              txtEnter={txtEnter}
              cursorVariant={cursorVariant}
            />
          }
        ></Route>
        <Route path="/sitemap.xml" element={<XmlPage />} />
      </Routes>
    </AnimatePresence>
  );
};
export default AnimatedRoute;
