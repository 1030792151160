import React from "react";
// XmlPage component to serve the XML file
const XmlPage = () => {
  // Use fetch or any method to load and display the XML file
  const xmlUrl = "/xml/sitemap.xml";
  console.log("xmUrl", xmlUrl)

  return (
    <div>
      <h1>XML Page</h1>
      <iframe
        title="XML Viewer"
        src={xmlUrl}
        width="100%"
        height="500"
      ></iframe>
    </div>
  );
};
export default XmlPage;
