import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useGetFetch = (url, reload) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isloaded, setIsLoaded] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setIsLoaded(true);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoaded(true);
        setErrors(err?.response);
      });
  }, [url, reload, navigate]);

  return { data, isloaded, errors, setData };
};

export default useGetFetch;
