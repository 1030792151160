import { BrowserRouter } from "react-router-dom";
import AnimatedRoute from "./components/AnimatedRoute.js";
import axios from "axios";
import ScrollToTop from "./components/ScrollToTop.js";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.baseURL = "https://cms.mg.com.gh";

function App() {

  
  return (
    // <SkeletonTheme baseColor="#313131" highlightColor="#525252">
    <BrowserRouter>
      <ScrollToTop />
      <AnimatedRoute />
    </BrowserRouter>
    // </SkeletonTheme>
  );
}

export default App;
